const config = {
  apiGateway: {
    REGION: 'us-west-2',
    INTERNAL_API_URL: 'https://fod52roiag.execute-api.us-west-2.amazonaws.com/prod',
    TARGET_API_URL: 'https://ebm2ofg8ka.execute-api.us-west-2.amazonaws.com/api',
    TEAMS_APP_URL: 'https://teams.staging.emeco.forwoodsafety.com/teams',
    QS_DASHBOARD_URL: '',
    CLIENT_TIMEZONE: 'America/Sao_Paulo'
  },
  reactApp: {
    VERSION: 'targets-1.6.0',
    HOSTNAME: 'https://targets.staging.emeco.forwoodsafety.com'
  }
};

export default config;